@import './variables';
@import './mixins/spacing';
@import './mixins/media-queries';
@import './mixins/hide-on-media-query';

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;

  @include lg {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px inherit;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgb(201, 201, 201);
      outline: 1px solid sleep;
      border-radius: 8px;
      &:hover {
        background: rgb(190, 190, 190);
      }
    }
  }
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable !important;
}

a {
  color: $accent-main;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Generate margin and padding values */
@include generate-spacing-classes();

/* Generate hide on media query classes */
@include hide-on-media-query();

/* Container */
.container {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 16px;
}

@include sm {
  .container {
    max-width: $container-sm-width;
  }
}

@include md {
  .container {
    max-width: $container-md-width;
  }
}

@include lg {
  .container {
    max-width: $container-lg-width;
  }
}

@include xl {
  .container {
    max-width: $container-xl-width;
  }
}

@include xxl {
  .container {
    max-width: $container-xxl-width;
  }
}

/* Text Color */
.text-foreground {
  color: #4c4c4c !important;
}

.text-primary {
  color: $primary-main !important;
}

.text-accent {
  color: $accent-main !important;
}

.text-warn {
  color: $warn-main !important;
}

.text-inherit {
  color: inherit !important;
}

.text-foreground-light {
  color: #7b7e83 !important;
}

/* Text Alignment */
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/* Text Transform */

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

/* Text Weight */

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

/* Text Overflow */
.text-overflow-ellipsis {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.text-nowrap-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.break-words {
  overflow-wrap: break-word;
}

/* Font Size */

.fs-1 {
  font-size: 1.5rem;
}

.fs-2 {
  font-size: 1.25rem;
}

.fs-3 {
  font-size: 1rem;
}

.fs-4 {
  font-size: 0.875rem;
}

.fs-5 {
  font-size: 0.75rem;
}

/* Word Break */

.word-break {
  word-break: break-word;
}

/* Align and Justify */
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

/* Display */
.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

/* Flex Direction */
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

/* Flex Wrap */
.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Flex Grow */
.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

/* Flex Gap */
.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 2rem;
}

/* Width and Height */
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.min-w-100 {
  min-width: 100%;
}

.max-w-100 {
  max-width: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

@include sm {
  .w-sm-100 {
    width: 100%;
  }

  .h-sm-100 {
    height: 100%;
  }

  .w-sm-auto {
    width: auto;
  }

  .h-sm-auto {
    height: auto;
  }
}

@include md {
  .w-md-100 {
    width: 100%;
  }

  .h-md-100 {
    height: 100%;
  }

  .w-md-auto {
    width: auto;
  }

  .h-md-auto {
    height: auto;
  }
}

@include lg {
  .w-lg-100 {
    width: 100%;
  }

  .h-lg-100 {
    height: 100%;
  }

  .w-lg-auto {
    width: auto;
  }

  .h-lg-auto {
    height: auto;
  }
}

@include xl {
  .w-xl-100 {
    width: 100%;
  }

  .h-xl-100 {
    height: 100%;
  }

  .w-xl-auto {
    width: auto;
  }

  .h-xl-auto {
    height: auto;
  }
}

/* Border Radius */
.rounded {
  border-radius: 12px;
}

.rounded-card {
  border-radius: 2rem;
}

.rounded-top {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.rounded-right {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.rounded-bottom {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.rounded-left {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.rounded-circle {
  border-radius: 50%;
}

/* Cursor */

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-text {
  cursor: text;
}

/* Overriding Material Design */
.mdc-dialog__surface {
  border-radius: 12px !important;
}

.ng2-pdf-viewer-container {
  border-radius: 4px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: white !important;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.05 !important;
}

// Hide spinners for number fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}
