@use '@angular/material' as mat;
@import './assets/scss/globals';
@import './assets/scss/variables';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

/* Theme */
$fontConfig: (
  display-4:
    mat.define-typography-level(112px, 112px, 400, 'Poppins', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Poppins', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Poppins', 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Poppins', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Poppins', 0em),
  title: mat.define-typography-level(20px, 32px, 400, 'Poppins', 0.0075em),
  subheading-2:
    mat.define-typography-level(16px, 28px, 400, 'Poppins', 0.0094em),
  subheading-1:
    mat.define-typography-level(15px, 24px, 400, 'Poppins', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 400, 'Poppins', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Poppins', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Poppins', 0.01em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Poppins', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Poppins', 1.5px),
);

$dark-text: #4c4c4c;

$dark-primary-text: rgba($dark-text, 1);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten($dark-background, 20%);
$dark-bg-alpha-4: rgba($dark-background, 0.04);
$dark-bg-alpha-12: rgba($dark-background, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba($light-background, 0.04);
$light-bg-alpha-12: rgba($light-background, 0.12);

$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

@include mat.all-component-typographies($fontConfig);
@include mat.core();

$mat-primary: (
  main: $primary-main,
  lighter: $primary-light,
  darker: $primary-dark,
  200: $primary-main,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: $accent-main,
  lighter: $accent-light,
  darker: $accent-dark,
  200: $accent-main,
  contrast: (
    main: $light-accent-text,
    lighter: $dark-accent-text,
    darker: $light-accent-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: $warn-main,
  lighter: $warn-light,
  darker: $warn-dark,
  200: $warn-main,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      foreground: $mat-light-theme-foreground,
      background: $mat-light-theme-background,
    ),
    density: 0,
  )
);

@include mat.core-theme($theme);
@include mat.all-component-themes($theme);

.container {
  .mat-horizontal-content-container {
    padding: 0 4px;
  }
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: $icons-font-family;
  .mat-badge-content {
    font-family: $font-family;
  }
}

.mat-elevation-z4 {
  background: white;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

// Workaround for: https://github.com/angular/components/issues/26818
:root {
  --mdc-switch-selected-handle-color: #{$primary-main};
  --mdc-switch-selected-hover-handle-color: #{$primary-main};
  --mdc-switch-selected-pressed-handle-color: #{$primary-main};
  --mdc-switch-selected-focus-handle-color: #{$primary-main};
  --mdc-switch-selected-pressed-track-color: #{lighten($primary-main, 70%)};
}
