/* Typography */
$font-family: "'Roboto', sans-serif";
$icons-font-family: 'Material Icons';

/* Colors */
$accent-main: #9b00c7;
$accent-light: #e1b3ee;
$accent-dark: #7f00b3;

$primary-main: #5c048a;
$primary-light: #ceb4dc;
$primary-dark: #41026d;

$warn-main: #d32f2f;
$warn-light: #ff6659;
$warn-dark: #d32f2f;

$light-text: #ffffff;
$dark-text: #000000;

$light-background: #fafafa;
$dark-background: #2c2c2c;

/* Grid */
$grid-gutter-width: 1.5rem;
$grid-columns: 12;

/* Breakpoints */
$columns-number: 12;
$screen-xs-max: 599px;
$screen-sm-min: 600px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1440px;

/* Container */
$container-max-width: 1200px;
$container-sm-width: 540px;
$container-md-width: 720px;
$container-lg-width: 900px;
$container-xl-width: 1140px;
$container-xxl-width: 1320px;
$gutter: 1rem;
