@mixin generate-spacing-classes() {
  $sides: top, bottom, left, right;
  $sizes: 0, 0.25, 0.5, 1, 1.5, 3;
  $spacing: 1rem;

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);
    .m-#{$i - 1} {
      margin: $spacing * $size !important;
    }

    .p-#{$i - 1} {
      padding: $spacing * $size !important;
    }

    .mx-#{$i - 1} {
      margin-left: $spacing * $size !important;
      margin-right: $spacing * $size !important;
    }

    .my-#{$i - 1} {
      margin-top: $spacing * $size !important;
      margin-bottom: $spacing * $size !important;
    }

    .px-#{$i - 1} {
      padding-left: $spacing * $size !important;
      padding-right: $spacing * $size !important;
    }

    .py-#{$i - 1} {
      padding-top: $spacing * $size !important;
      padding-bottom: $spacing * $size !important;
    }

    @include sm {
      .m-sm-#{$i - 1} {
        margin: $spacing * $size !important;
      }

      .p-sm-#{$i - 1} {
        padding: $spacing * $size !important;
      }

      .mx-sm-#{$i - 1} {
        margin-left: $spacing * $size !important;
        margin-right: $spacing * $size !important;
      }

      .my-sm-#{$i - 1} {
        margin-top: $spacing * $size !important;
        margin-bottom: $spacing * $size !important;
      }

      .px-sm-#{$i - 1} {
        padding-left: $spacing * $size !important;
        padding-right: $spacing * $size !important;
      }

      .py-sm-#{$i - 1} {
        padding-top: $spacing * $size !important;
        padding-bottom: $spacing * $size !important;
      }
    }

    @include md {
      .m-md-#{$i - 1} {
        margin: $spacing * $size !important;
      }

      .p-md-#{$i - 1} {
        padding: $spacing * $size !important;
      }

      .mx-md-#{$i - 1} {
        margin-left: $spacing * $size !important;
        margin-right: $spacing * $size !important;
      }

      .my-md-#{$i - 1} {
        margin-top: $spacing * $size !important;
        margin-bottom: $spacing * $size !important;
      }

      .px-md-#{$i - 1} {
        padding-left: $spacing * $size !important;
        padding-right: $spacing * $size !important;
      }

      .py-md-#{$i - 1} {
        padding-top: $spacing * $size !important;
        padding-bottom: $spacing * $size !important;
      }
    }

    @include lg {
      .m-lg-#{$i - 1} {
        margin: $spacing * $size !important;
      }

      .p-lg-#{$i - 1} {
        padding: $spacing * $size !important;
      }

      .mx-lg-#{$i - 1} {
        margin-left: $spacing * $size !important;
        margin-right: $spacing * $size !important;
      }

      .my-lg-#{$i - 1} {
        margin-top: $spacing * $size !important;
        margin-bottom: $spacing * $size !important;
      }

      .px-lg-#{$i - 1} {
        padding-left: $spacing * $size !important;
        padding-right: $spacing * $size !important;
      }

      .py-lg-#{$i - 1} {
        padding-top: $spacing * $size !important;
        padding-bottom: $spacing * $size !important;
      }
    }

    @include xl {
      .m-xl-#{$i - 1} {
        margin: $spacing * $size !important;
      }

      .p-xl-#{$i - 1} {
        padding: $spacing * $size !important;
      }

      .mx-xl-#{$i - 1} {
        margin-left: $spacing * $size !important;
        margin-right: $spacing * $size !important;
      }

      .my-xl-#{$i - 1} {
        margin-top: $spacing * $size !important;
        margin-bottom: $spacing * $size !important;
      }

      .px-xl-#{$i - 1} {
        padding-left: $spacing * $size !important;
        padding-right: $spacing * $size !important;
      }

      .py-xl-#{$i - 1} {
        padding-top: $spacing * $size !important;
        padding-bottom: $spacing * $size !important;
      }
    }
  }

  @each $side in $sides {
    @for $i from 1 through length($sizes) {
      $size: nth($sizes, $i);
      .m#{str-slice($side, 0, 1)}-#{$i - 1} {
        margin-#{$side}: $spacing * $size !important;
      }

      .p#{str-slice($side, 0, 1)}-#{$i - 1} {
        padding-#{$side}: $spacing * $size !important;
      }

      @include sm {
        .m#{str-slice($side, 0, 1)}-sm-#{$i - 1} {
          margin-#{$side}: $spacing * $size !important;
        }
        .p#{str-slice($side, 0, 1)}-sm-#{$i - 1} {
          padding-#{$side}: $spacing * $size !important;
        }
      }

      @include md {
        .m#{str-slice($side, 0, 1)}-md-#{$i - 1} {
          margin-#{$side}: $spacing * $size !important;
        }
        .p#{str-slice($side, 0, 1)}-md-#{$i - 1} {
          padding-#{$side}: $spacing * $size !important;
        }
      }

      @include lg {
        .m#{str-slice($side, 0, 1)}-lg-#{$i - 1} {
          margin-#{$side}: $spacing * $size !important;
        }
        .p#{str-slice($side, 0, 1)}-lg-#{$i - 1} {
          padding-#{$side}: $spacing * $size !important;
        }
      }

      @include xl {
        .m#{str-slice($side, 0, 1)}-xl-#{$i - 1} {
          margin-#{$side}: $spacing * $size !important;
        }
        .p#{str-slice($side, 0, 1)}-xl-#{$i - 1} {
          padding-#{$side}: $spacing * $size !important;
        }
      }
    }

    .m#{str-slice($side, 0, 1)}-auto {
      margin-#{$side}: auto !important;
    }
  }

  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include sm {
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mx-sm-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @include md {
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mx-md-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @include lg {
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mx-lg-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @include xl {
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .mx-xl-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
