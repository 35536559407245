@import '/src/assets/scss/variables';
@import '/src/assets/scss/mixins/media-queries';

@mixin hide-and-show-generator($breakpoint) {
  .#{$breakpoint}-hide {
    display: none !important;
  }

  .#{$breakpoint}-show {
    display: block !important;
  }
}

@mixin hide-on-media-query() {
  @include hide-and-show-generator('xs');

  @include sm {
    @include hide-and-show-generator('sm');
  }

  @include md {
    @include hide-and-show-generator('md');
  }

  @include lg {
    @include hide-and-show-generator('lg');
  }

  @include xl {
    @include hide-and-show-generator('xl');
  }
}
